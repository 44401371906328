import React from "react";
import { convertLocalTimeToTimezoneTimeOnFormated } from "../../utils/getDate";
import { scrollToBottom } from "../../pages/private/Conversations/Messages/utils";
import ChatWindowHeader from "./ChatWindowHeader/ChatWindowHeader";
import ChatWindowContent from "./ChatWindowContent/ChatWindowContent";
import ChatWindowComposer from "./ChatWindowComposer/ChatWindowComposer";
import "./ChatWindow.css";

const ChatWindow = ({
  openMenuData,
  toggleMenu,
  loading,
  messages,
  setMessages,
  chatWindowData,
  timeZone,
  number,
  sendMessages,
  loadingSendMessages,
  messagesContainerRef,
  activeModalDelete,
  loadingMoreMessages,
  errorMoreMessages,
  getMoreMessages,
  changeAiEnabled,
  noSendMessage,
  noHover,
  pk,
  downloadVoicemail,
  myInbox,
  loadAudio,
  hasMoreMessages,
}) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const submitMessage = async (messageBody, imgMessages, contactMessage) => {
    let currentMessages = messages;

    if (imgMessages && imgMessages.length > 0) {
      imgMessages.forEach((img) => {
        currentMessages.push({
          message_participant: number,
          message_body: null,
          message_timestamp: convertLocalTimeToTimezoneTimeOnFormated(),
          sending: true,
          message_mms_media: null,
          url: img.data_url,
        });
      });
    }

    if (messageBody) {
      currentMessages.push({
        message_participant: number,
        message_body: messageBody,
        message_timestamp: convertLocalTimeToTimezoneTimeOnFormated(),
        sending: true,
      });
    }

    setMessages(currentMessages);
    scrollToBottom(messagesContainerRef)
    forceUpdate();

    await sendMessages(messageBody, imgMessages, contactMessage);
  };

  const deleteFollowupScheduleMessage = () => {
    setMessages((prev) => prev.filter((element) => !element?.isFollowUpFake))
  }

  return (
    <>
      <div className="itp-chat-window">
        <ChatWindowHeader
          chatWindowData={chatWindowData}
          openMenuData={openMenuData}
          toggleMenu={toggleMenu}
          activeModalDelete={activeModalDelete}
          changeAiEnabled={changeAiEnabled}
          myInbox={myInbox}
          loading={loading}
        />

        <ChatWindowContent
          messagesContainerRef={messagesContainerRef}
          messages={messages}
          timeZone={timeZone}
          chatWindowData={chatWindowData}
          activeModalDelete={activeModalDelete}
          loading={loadingMoreMessages}
          error={errorMoreMessages}
          getMoreMessages={getMoreMessages}
          loadingMoreMessages={loadingMoreMessages}
          noHover={noHover}
          downloadVoicemail={downloadVoicemail}
          loadingPage={loading}
          loadAudio={loadAudio}
          deleteFollowupScheduleMessage={deleteFollowupScheduleMessage}
          number={number}
          hasMoreMessages={hasMoreMessages}
        />

        {!noSendMessage && (
          <ChatWindowComposer
            submitMessage={submitMessage}
            pk={pk}
            loadingSendMessages={loadingSendMessages}
            chatWindowData={chatWindowData}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export default ChatWindow;
