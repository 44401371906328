import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col } from "reactstrap";
import { LoadMessages, MessageItem, MessageSeparator, UserWhoSentMessage } from "./components";
import "../ChatWindow.css";

const ChatWindowContent = ({
  messagesContainerRef,
  messages,
  timeZone,
  chatWindowData,
  activeModalDelete,
  loading,
  getMoreMessages,
  noHover,
  downloadVoicemail,
  error,
  loadingPage,
  loadAudio,
  deleteFollowupScheduleMessage,
  number,
  loadingMoreMessages,
  hasMoreMessages,
}) => {
  const firstLoad = useRef(true)
  const firstExecutedHandleScroll = useRef(false)

  const [lastVisibleMessageId, setLastVisibleMessageId] = useState(null);

  const calculateMessageHeight = (messageIndex) => {
    const messageElement = messagesContainerRef.current.querySelector(
      `#message-${messageIndex}`
    );
    return messageElement ? messageElement.clientHeight : 0;
  };

  // Calculate the scroll position based on the height of messages
  const calculateScrollPosition = (messageIndex) => {
    let scrollPosition = 0;
    for (let i = 0; i < messageIndex; i++) {
      scrollPosition += calculateMessageHeight(i);
    }
    return scrollPosition;
  };

  const download = (element) => {
    if (downloadVoicemail) {
      downloadVoicemail(element);
    }
  };

  useEffect(() => {
    if (messages && messages.length > 38 && !loadingPage) {
      // Load new messages and update the state

      // Find the index of the last visible message among the new messages
      const newIndex = messages.findIndex(
        (message) => message.pk === lastVisibleMessageId
      );

      // Calculate the new scroll position to maintain the last visible message's position
      const newScrollPosition = calculateScrollPosition(newIndex);

      // Scroll the chat window to the new position
      messagesContainerRef.current.scrollTop = newScrollPosition;
    } else if (loadingPage) {
      setTimeout(() => {
        if (messagesContainerRef?.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
      }, 10);
    }
  }, [messages, lastVisibleMessageId, loadingPage]);

  useEffect(() => {
    const handleScroll = async () => {
      if (
        messagesContainerRef.current && 
        messagesContainerRef.current.scrollTop === 0 && 
        !loadingMoreMessages && // Prevent multiple calls
        hasMoreMessages // Stop fetching if no more messages
      ) {
        // Save the current last visible message's id
        if (firstLoad.current) {
          firstLoad.current = false;
          const lastVisibleId = messages[messages.length - 1]?.pk;
          setLastVisibleMessageId(lastVisibleId);
        } else {
          const lastVisibleId = messages[0]?.pk;
          setLastVisibleMessageId(lastVisibleId);
        }
      
        if (firstExecutedHandleScroll.current) {
          await getMoreMessages();
        }
      }
      

      if (messagesContainerRef.current) {
        const separators = Array.from(
          messagesContainerRef.current.querySelectorAll(
            ".itp-chat-window-content__container-separator"
          )
        );

        for (let i = 0; i < separators.length - 1; i++) {
          const separator = separators[i];
          const nextSeparator = separators[i + 1];
          const rect = separator.getBoundingClientRect();
          const nextRect = nextSeparator.getBoundingClientRect();

          if (rect.bottom > 0 && rect.top < window.innerHeight) {
            separator.classList.add("sticky");

            if (nextRect.top > window.innerHeight) {
              nextSeparator.classList.remove("sticky");
            }
          } else {
            separator.classList.remove("sticky");
          }
        }

      }
    };

    if (messagesContainerRef.current && !loadingPage) {
      messagesContainerRef.current.addEventListener("scroll", handleScroll);
    }

    if (!loadingPage) {
      handleScroll();
    } else {
      firstExecutedHandleScroll.current = true
    }

    return () => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages, loadingPage]);

  const fakeMessages = [{other: true},{},{other: true},{},{other: true},{},{other: true},{},{other: true},{},{other: true},{},{other: true},{},{other: true},{},{other: true},{},{other: true},{}]
  const allMessages = loadingPage ? fakeMessages : messages

  return (
    <>
      {/* {messages && messages[0] && returnSeparator(messages[0], -1, messages)} */}
      <div ref={messagesContainerRef} className="itp-chat-window-content">  
        <LoadMessages loading={loading} error={error} />

        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          {allMessages && allMessages.map((element, i) => (
            <Fragment key={i}>

              {!loadingPage && (
                <MessageSeparator 
                  timeZone={timeZone}
                  element={element}
                  i={i}
                  array={allMessages}
                />
              )}
              
              <MessageItem 
                index={i} 
                element={element} 
                chatWindowData={chatWindowData} 
                messages={messages} 
                noHover={noHover}
                activeModalDelete={activeModalDelete}
                download={download}
                loadingPage={loadingPage}
                loadAudio={loadAudio}
                deleteFollowupScheduleMessage={deleteFollowupScheduleMessage}
                number={number}
              />

              <UserWhoSentMessage 
                element={element}
                chatWindowData={chatWindowData}
                index={i}
                messages={messages}
              />
            </Fragment>
          ))}
        </Col>
      </div>
    </>
  );
};

export default ChatWindowContent;
